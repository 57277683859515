import { JsonProperty, Serializable } from "typescript-json-serializer"
import EstimationShapeValue from "@/types/Estimation/EstimationShapeValue"

@Serializable()
export default class EstimationShape {
  @JsonProperty() key!: string

  @JsonProperty() title!: string

  @JsonProperty() unit!: string

  @JsonProperty({ type: EstimationShapeValue }) value: Array<EstimationShapeValue> = []
}
