import { JsonProperty, Serializable } from "typescript-json-serializer"
import DiamondFile from "@/types/DiamondFile"
import EstimationShape from "@/types/Estimation/EstimationShape"
import EstimationColor from "@/types/Estimation/EstimationColor"

@Serializable()
export default class EstimationProductCategory {
  @JsonProperty("estimation_product_category_id") productCategoryId!: number

  @JsonProperty() title!: string

  @JsonProperty({ type: DiamondFile }) file: DiamondFile = new DiamondFile()

  @JsonProperty({ type: EstimationShape }) shape!: EstimationShape

  @JsonProperty({ type: EstimationColor }) color?: EstimationColor

  hover = false
}
