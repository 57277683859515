import { JsonProperty, Serializable } from "typescript-json-serializer"
import EstimationColorValue from "@/types/Estimation/EstimationColorValue"

@Serializable()
export default class EstimationColor {
  @JsonProperty() key!: string

  @JsonProperty() title!: string

  @JsonProperty() unit?: string

  @JsonProperty({ type: EstimationColorValue }) value: Array<EstimationColorValue> = []
}
