import { JsonProperty, Serializable } from "typescript-json-serializer"
import DiamondFile from "@/types/DiamondFile"

@Serializable()
export default class EstimationProductGroup {
  @JsonProperty("estimation_product_group_id") productGroupId!: number

  @JsonProperty() title!: string

  @JsonProperty({ type: DiamondFile }) file: DiamondFile = new DiamondFile()

  hover = false
}
