import DiamondBaseAPI from "@/api/Api"
import ResponseBase from "@/types/ResponseBase"
import EstimationProductGroup from "@/types/Estimation/EstimationProductGroup"
import EstimationProductCategory from "@/types/Estimation/EstimationProductCategory"
import EstimationColor from "@/types/Estimation/EstimationColor"
import EstimationResult from "@/types/Estimation/EstimationResult"
import DiamondFile from "@/types/DiamondFile"
import EstimationShapeField from "@/types/Estimation/EstimationShapeField"
/* eslint-disable @typescript-eslint/camelcase */
export default class EstimationApi extends DiamondBaseAPI {
  static listProductGroups(): Promise<ResponseBase<EstimationProductGroup[]>> {
    return this.api.get("estimation-product-group").then(({ data }) => data)
  }

  static listProductCategories(productGroupId: number): Promise<ResponseBase<EstimationProductCategory[]>> {
    const params = {
      // eslint-disable-next-line @typescript-eslint/camelcase
      product_group_id: productGroupId
    }
    return this.api.get("estimation-product-category", { params }).then(({ data }) => data)
  }

  static getProductCategoryDetail(categoryId: number): Promise<ResponseBase<EstimationProductCategory>> {
    const params = {
      // eslint-disable-next-line @typescript-eslint/camelcase
      product_category_id: categoryId
    }
    return this.api.get("estimation-product-category/detail", { params }).then(({ data }) => data)
  }

  static listEstimationFields(categoryId: number, shapeId: number): Promise<ResponseBase<EstimationShapeField>> {
    const params = {}
    // eslint-disable-next-line @typescript-eslint/camelcase
    // @ts-ignore
    if (categoryId !== -1) params.product_category_id = categoryId
    // eslint-disable-next-line @typescript-eslint/camelcase
    // @ts-ignore
    if (shapeId !== -1) params.estimation_shape_id = shapeId
    return this.api.get("estimation-field", { params }).then(({ data }) => data)
  }

  static listEstimationColors(categoryId: number): Promise<ResponseBase<EstimationColor[]>> {
    const params = {
      // eslint-disable-next-line @typescript-eslint/camelcase
      product_category_id: categoryId
    }
    return this.api.get("estimation-color", { params }).then(({ data }) => data)
  }

  static getEstimationProduct(colorId: number, categoryId: number, shapeId = -1): Promise<ResponseBase<DiamondFile>> {
    const params = {
      product_category_id: `${categoryId}`
    }
    if (colorId && colorId !== -1) {
      // @ts-ignore
      params.color_id = `${colorId}`
    }
    if (shapeId !== -1) {
      // @ts-ignore
      params.shape_id = `${shapeId}`
    }
    return this.api.get("estimation-product", { params }).then(({ data }) => data)
  }

  static calculate(
    shapeId: number,
    categoryId: number,
    colorId: number,
    body: unknown
  ): Promise<ResponseBase<EstimationResult[]>> {
    const params = {
      estimation_product_category_id: categoryId
    }
    if (shapeId !== -1) {
      // @ts-ignore
      params.estimation_shape_id = shapeId
    }
    if (colorId !== -1) {
      // @ts-ignore
      params.estimation_color_id = colorId
    }
    return this.api.post("estimation-calculate", body, { params }).then(({ data }) => data)
  }
}
